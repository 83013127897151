define('service-data-locales',[
	'module',
	'jquery',
	'underscore',
	'util',
	'i18next',
	'service',
	'service-config-loader',
	'service-data-book',
	'manager-event'
], function (module, $, _, Util, i18n, Service, LoaderConfigSrvc, BookSrvc, EventManager) {

	// Set up all inheritable **BaseConfig** properties and methods.
	return Service.extend({}, {

		_name: module.id,

		hasFallback: true,

		load: function (fallback) {

			console.log('LocalesService => Loading', fallback);

			this.isFallback = fallback;

			var language = 'en';

			this._promise = $.Deferred();

			if ( !fallback )
			{
				language = LoaderConfigSrvc.get('language') || LoaderConfigSrvc.get('langid');

				console.log('LocalesService => Forced language:', language);

				if ( !language ) // Guess
				{
					language = this.guessLanguage();
				}
			}

			console.info('LocalesService => Using language:', language);

			i18n.init({
				lng: language.toLowerCase() + '-' + language.toUpperCase(),
				useCookie: false,
				fallbackLng: false,
				load: 'current',
				debug: false,
				ns: 'viewer',
				customLoad: $.proxy(this.i18nCustomLoad, this)
			});

			return this._promise;
		},

		guessLanguage: function() {
			// Guess language if not defined in config
			var supportedLanguages = BookSrvc.feature('localization.allowedInternLanguages');

			console.log('LocalesService => Supported languages:', supportedLanguages);

			console.log('LocalesService => Guessing language...');

			var cookieLanguage = Util.cookie.get('lang');
			console.log('LocalesService => Cookie language:', cookieLanguage);

			if ( supportedLanguages.indexOf(cookieLanguage) >= 0 )
			{
				console.log('LocalesService => Found language:', cookieLanguage);
				return cookieLanguage;
			}

			var browserLanguages = ( window.navigator.languages || window.navigator.language || window.navigator.browserLanguage );

			if ( !_.isArray(browserLanguages) ) browserLanguages = [browserLanguages];

			console.log('LocalesService => Browser languages:', browserLanguages);

			var language = 'en';
			$.each(browserLanguages, function(i, v){
				if ( !_.isString(v) ) return;
				var code = v.split('-')[0];
				console.log('LocalesService => Testing:', code);
				if ( supportedLanguages.indexOf(code) >= 0 )
				{
					console.log('LocalesService => Found language:', code);
					language = code;
					return false;
				}
			});

			return language;
		},

		t: function(key, options){
			//console.log('LocalesSrvc => Translating:', key, options);
			return i18n.t(key, options);
		},

		i18nCustomLoad: function (lng, ns, options, loadComplete) {

			var url = Calameo.defaults.base.url + ( LoaderConfigSrvc.get('buildid') || '0000-000000' ) + '/pinwheel/viewer/locales/' + ns + '/' + lng + '.js';
			var customURL = LoaderConfigSrvc.get('localesurl');
			url = customURL && !this.isFallback ? customURL : url;

			// Check SSL
			url = Util.url.prepare( url );

			console.info('LocalesService => Loading:', url);

			$.ajax({
				url: url,
				dataType: 'jsonp',
				jsonpCallback: '_i18n',
				cache: true
			})
			.done(
				$.proxy(this.onSuccess, this, loadComplete)
			)
			.fail(
				$.proxy(this.onError, this, loadComplete)
			);
		},

		onSuccess: function (loadComplete, data, textStatus, jXhr) {
			console.log('LocalesService => Loaded:', data);
			loadComplete(null, data);
			this._promise.resolve();
		},

		onError: function (loadComplete, jXhr, text) {
			loadComplete('error');
			EventManager.trigger('debug', {type: 'error', msg: this._name + ' => Loading error', metas: {url: jXhr.url}});
			this._promise.reject(913, 'Unable to load locales');
		}
	});

});
